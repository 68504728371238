import React from "react";
import styles from "./JoinTaBuddy.module.css"

const JoinTaBuddy = () => {
  return (

    <div className={styles.tc_container}>
      <div className={styles.tc_subcontainer}>
        <div className={styles.tc_heading}>
        Want to Supercharge Your Recruiting?
        </div>

        <div className="tc-points" style={{ paddingBottom: "1rem" }}>
          <div className="tc-subpoints">
            <div
              className="tc-point-content"
              style={{ fontSize: "18px", textAlign: "center", marginTop:"0.5rem" }}
            >
              Unlock the full potential of your hiring process with TA buddy, your <br /> personal assistant for finding and engaging top talent.
            </div>
          </div>{" "}
        </div>

        <button
          className={styles.join_diploma}
          onClick={()=>window.open("https://wa.me/917996496999", "_blank")}
        >
         Get Demo for TA buddy Suite
        </button>
        {/* <button
          className={styles.join_diploma}
          style={{marginTop:"0.5rem"}}
        >
          
        You’re now just one click away from Quick Hire!
        </button> */}
      </div>
    </div>
  );
};

export default JoinTaBuddy;