import React from "react";
import styles from "./Help.module.css";

const Help = () => {
  return (
    <div className={styles.helpMainContainer}>
      <div className={styles.helpSubContainer}>
        <div className={styles.helpContainerInfo}>
          <div className={styles.helpHeading}>why TA buddy?</div>
          <div className={styles.helpSubHeading}>
            <span>
              TA buddy handles over 80% of your daily recruiting tasks,
            </span>{" "}
            allowing you to focus on what really matters—hiring the right
            talent.
          </div>

          <div className={styles.helpPointsContainer}>
            <div className={styles.helpPointContainer}>
              <img src="./img/help.svg" alt="" />
              <div className={styles.helpPoint}>Faster Sourcing</div>
            </div>

            <div className={styles.helpPointContainer}>
              <img src="./img/help.svg" alt="" />
              <div className={styles.helpPoint}>Smarter Screening</div>
            </div>

            <div className={styles.helpPointContainer}>
              <img src="./img/help.svg" alt="" />
              <div className={styles.helpPoint}>Improved Engagement</div>
            </div>
          </div>
        </div>

        <div className={styles.helpAmountContainer}>
          <div className={styles.helpAmount}>
            <span>80%</span>
            <span>Reduction in Recruiting time</span>
          <img src="./img/arrow.png" alt="arrow" className={styles.arrowImg}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
