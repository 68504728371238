import React from "react";
import styles from "./SubFotter.module.css";

const SubFooter = () => {
  return (
    <>
      <div className={styles.subFooterContainer}>
        <div className={styles.footerHeading}>Enjoying Free Sourcing?</div>
        <div className={styles.subFooterCardsContainer}>
          {/* <div className={styles.subFooterCardContainer}>
            <div className={styles.cardHeading}>Sourcing Drive!</div>
            <div className={styles.cardDesc}>Liking What You See? Take it to the next level with our exclusive Sourcing Drive! Access a curated selection of top-tier talent tailored for your most critical roles.</div>
            <button
              className={styles.bannerButton2}
              style={{ borderRadius: "10px", width:"100%" }}
            >
              Learn More
            </button>
          </div> */}

          <div className={styles.subFooterCardContainer}>
            <div className={styles.cardHeading}>TA buddy Suite</div>
            <div className={styles.cardDesc}>Unlock the full potential with TA buddy Suite! Streamline your entire hiring process with advanced tools for sourcing, screening, and engagement—all in one platform.</div>
            <button
              className={styles.bannerButton2}
              style={{ borderRadius: "10px", width:"100%" }}
              onClick={()=> window.open("https://wa.me/917996496999","_blank")}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
