import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <div className={styles.aboutContainerAshfaq}>
      <div className={styles.aboutSubContainerAshfaq}>
        <div className={styles.aboutHeading}>
          World’s first Recruiting solution built by a RecruitingMonk
        </div>

        <div className={styles.aboutSubHeading}>
          Ashfaq Ahmed is the founding father of big data principles and persona
          driven hiring. It is an innovative approach of addressing sourcing
          problem at scale via the lens of a Data Analyst. Ashfaq has been
          training Recruiters on this new philosophy of sourcing & now, he has
          plugged that brains to TA buddy
        </div>
      </div>

      <div className={styles.aboutImage}>
        <img src="./ashfaq_ahmad.jpeg" alt="" />
      </div>
    </div>
  );
};

export default About;
